<template>
  <v-row class="pb-10">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn @click="dialog=true" color="primary" :ripple="false"><v-icon left>mdi-plus-box</v-icon>Crear automatización</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0" v-if="loadingRules">
      <v-skeleton-loader class="bgPanel mb-5 rounded-md" v-for="item in 3" :key="item" type="image" />
    </v-col>
    <template v-else>
      <v-empty-state class="mt-5" v-if="!groupsRules.length" type="automation" id="Automatización" customClass="mt-8" :hiddenDescription="true" :isFree="true" title="No existen automatizaciones para mostrar">
        <template v-slot:actions>
          <v-btn class="ml-2" @click="dialog=true" color="primary" :ripple="false"><v-icon class="mr-2">mdi-plus-box</v-icon>Crear automatización</v-btn>
        </template>
      </v-empty-state>
      <v-col class="mt-0 px-0" v-for="(group, index) in groupsRules" :key="index">
        <v-card flat>
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-4 subtitle-2 secondary--text font-weight-semibold">
              <template v-if="group.action === 'ACCEPT'">Documentos aceptados</template>
              <template v-if="group.action === 'REJECT'">Documentos rechazados</template>
              <template v-if="group.action === 'NOTHING'">Se responde de forma manual</template>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerEdit(group)" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-pencil</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
          <v-card-text class="">
            <div class="d-flex align-center" style="overflow: hidden">
              <div style="flex:1; min-width: 180px;">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                      <v-icon color="primary">mdi-playlist-check</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Reglas</v-list-item-title>
                      <v-list-item-subtitle>Si <span class="primary--text">{{ group.operator === 'Y' ? 'todas' : 'algunas' }}</span> las reglas seleccionadas se cumplen</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <div class="ma-0" style="width: 360px;">
                <v-row align="center" justify="center" no-gutters>
                  <img class="mr-2" :src="require(`@/assets/automation/document-${group.action.toLowerCase()}${!group.recipient.length ? '' : '-1'}.svg`)" :min-width="300" :width="400" :height="200" :aspect-ratio="12/9"/>
                </v-row>
              </div>
              <div style="flex:1; min-width: 180px;">
                <v-card class="mt-0" outlined>
                  <v-list-item>
                    <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                      <v-icon color="primary">mdi-email</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Enviar un mail</v-list-item-title>
                      <v-list-item-subtitle>{{ group.recipient.toString().replace(/,/g, ', ') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <!-- <v-card class="my-5" outlined>
                  <v-list-item>
                    <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                      <v-icon color="primary">mdi-webhook</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Activar un webhook</v-list-item-title>
                      <v-list-item-subtitle>https://somosintercorp.net/recept</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card> -->
                <!-- <v-card outlined>
                  <v-list-item>
                    <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                      <v-icon color="primary">mdi-folder-network</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Publicar el documento en un SFTP</v-list-item-title>
                      <v-list-item-subtitle>192.158.1.38</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card> -->
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </template>

    <!-- dialog create/edit-->
    <v-dialog v-model="dialog" fullscreen persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <v-card class="bgSearch" :img="require(`@/assets/automation/background-modal.svg`)">
        <v-card-title class="pa-0 bgSearch">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Reglas de automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear(), dialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
          <v-card-text class="py-4 px-5 text-center fontBody--text">
            <v-col cols="9" class="mx-auto my-5">
              <v-card class="background d-block" flat>
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="subtitle-2 fontBody--secondary font-weight-semibold">Nombre de la automatización</div>
                    <v-col class="pa-0 pl-5">
                      <v-text-field v-model="$v.name.$model" :error="$v.name.$error" outlined single-line dense hide-details />
                    </v-col>
                  </div>
                </v-card-text>
              </v-card>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="py-6 px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">
                  <span class="mr-2">Si</span>
                  <v-chip class="mr-2 pl-1 pr-0" color="bgSearch" text-color="primary">
                    <v-chip class="mr-2" :color="currentRule.operator === 'Y' ? 'primary' : 'white'" small @click="currentRule.operator = 'Y'">todas</v-chip>
                    <v-chip class="mr-2" :color="currentRule.operator === 'O' ? 'primary' : 'barCompliance'" small @click="currentRule.operator = 'O'">algunas</v-chip>
                  </v-chip>
                  {{ currentRule.operator === 'Y' ? 'las siguientes reglas se cumplen' : 'de las siguientes reglas se cumplen' }}
                </v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <v-card class="background d-block" flat>
                <v-card-text class="pt-8 pb-2">
                  <v-row align="center" v-for="(r, i) in rules" :key="i">
                    <v-col class="pa-0 pl-5">
                      <v-autocomplete
                        v-model="$v.rules.$each[i].attributeModel.$model"
                        :error="$v.rules.$each[i].attributeModel.$error"
                        :items="attribute"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                        height="30"
                        single-line
                        outlined
                        dense
                        hide-details
                        @input="r.comparisonOperatorsModel = null"
                      />
                    </v-col>
                      <v-col class="pa-0 pl-5">
                      <v-autocomplete
                        v-model="$v.rules.$each[i].comparisonOperatorsModel.$model"
                        :error="$v.rules.$each[i].comparisonOperatorsModel.$error"
                        :items="comparisonOperators[r.attributeModel]?.list || []"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                        height="30"
                        single-line
                        outlined
                        dense
                        hide-details
                        :disabled="!r.attributeModel"
                      />
                    </v-col>
                    <v-col class="pa-0 pl-5">
                      <v-text-field
                        v-if="comparisonOperators[r.attributeModel]?.input === 'number'"
                        v-model="$v.rules.$each[i].value.$model"
                        :error="$v.rules.$each[i].value.$error"
                        outlined
                        single-line
                        dense hide-details
                        :disabled="!r.comparisonOperatorsModel"
                      />
                      <v-autocomplete
                        v-else-if="comparisonOperators[r.attributeModel]?.input === 'autocomplete'"
                        v-model="$v.rules.$each[i].value.$model"
                        :error="$v.rules.$each[i].value.$error"
                        :items="comparisonOperators[r.attributeModel]?.inputList ?? []"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition', 'max-width': 400 }"
                        height="30"
                        single-line
                        outlined
                        dense
                        hide-details
                        chip
                        :multiple="r.attributeModel === 'Giro del receptor'"
                        :disabled="!r.comparisonOperatorsModel"
                        :prefix="r.value?.length >= 1 ? `${r.value?.length === 1 ? `${r.value?.length} seleccionado` : `${r.value?.length} seleccionados`}` : ''"
                      >
                        <template v-slot:selection="data">
                          <span v-if="data"></span>
                        </template>
                      </v-autocomplete>
                      <v-text-field v-else outlined single-line dense hide-details :disabled="!r.comparisonOperatorsModel" />
                    </v-col>
                    <v-col cols="1" style="max-width: 40px">
                      <v-btn text color="secondary" @click="rules.splice(i, 1)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-col class="text-left">
                    <v-btn class="font-weight-medium body-2" text color="primary" @click="addRow">+ Agregar otro</v-btn>
                  </v-col>
                </v-card-text>
              </v-card>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Entonces</v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <!-- accept, refused, equall -->
              <v-card v-if="dialog" :class="documentOptions.find(({id}) => id === currentRule.type)?.color || 'lightBlue'" class="d-block" flat>
                <v-card-text class="py-0">
                  <v-row no-gutters class="ma-0">
                    <v-col class="d-flex align-center">
                      <div class="subtitle-2 fontBody--secondary font-weight-semibold">El documento</div>
                      <v-col class="pa-0 pl-5">
                        <v-autocomplete
                          v-model="currentRule.type"
                          :items="documentOptions"
                          :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                          height="30"
                          item-text="text"
                          item-value="id"
                          single-line
                          outlined
                          dense
                          hide-details
                        />
                      </v-col>
                    </v-col>
                    <v-col class="text-right pa-0">
                      <img class="mr-2 mb-n1" :src="require(`@/assets/automation/${currentRule.type.toLowerCase()}.svg`)" :height="100" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- end accept, refused, equall -->
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Y</v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <v-card class="background d-block" flat>
                <v-card-text class="py-6 text-left">
                  <div class="subtitle-2 fontBody--secondary font-weight-semibold">Opcional</div>
                  <div class="mt-1 subtitle-2 fontBody--secondary font-weight-semibold">Configuración personalizada del grupo</div>
                  <v-switch v-model="currentRule.send_mail" flat label="Envíar un email" hide-details color="success" :ripple="false"></v-switch>
                  <div class="pt-5" v-if="currentRule.send_mail">
                    Escribe la casilla (máximo 3 mails)
                    <v-col class="px-0">
                      <v-autocomplete
                        v-model="currentRule.recipient"
                        hide-details
                        single-line
                        color="primary"
                        dense
                        outlined
                        :items="membersList"
                        multiple
                        :chips="false"
                        item-text="email"
                        item-value="email"
                        return-object
                        @input="verifyEmails"
                        item-disabled="customDisabled"
                        :prefix="currentRule.recipient.length >= 1 ? `${currentRule.recipient.length === 1 ? `${currentRule.recipient.length} seleccionado` : `${currentRule.recipient.length} seleccionados`}` : ''"
                        no-data-text="No existen miembros para mostrar"
                      >
                        <template v-slot:selection="{ props }">
                          <span v-if="props" style=""></span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-row class="ml-1" no-gutters>
                      <v-col class="pa-0">
                        <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in currentRule.recipient" :key="`${member}${i}`" close @click:close="memberRemove(member, i)" color="lightBlue" small label close-icon="mdi-close">
                          {{member?.email ?? member }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- <v-switch v-model="activeWebhook" flat label="Activar un webhook" hide-details color="success" :ripple="false"></v-switch>
                  <div class="pt-5" v-if="activeWebhook">
                    Escribe la URL de conexión
                    <v-col class="px-0">
                      <v-text-field v-model="currentRule.webhook" outlined single-line dense hide-details />
                    </v-col>
                  </div>
                  <v-switch v-model="activeSftp" flat label="Publicar el documento en un SFTP" hide-details color="success" :ripple="false"></v-switch>
                  <v-col class="pt-5 pa-0" v-if="activeSftp">
                    Escribe la URL
                    <v-col class="px-0">
                      <v-text-field v-model="currentRule.sftp" outlined single-line dense  hide-details />
                    </v-col>
                    <v-col class="d-flex pa-0">
                      <div>
                        Subir certificado
                        <v-icon color="secondary" small>mdi-information</v-icon>
                      </div>
                      <div class="ml-4">
                        <v-col cols="12" class="py-0 mt-n3">
                          <v-file-input class="input-file-automation" type="file" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                        </v-col>
                      </div>
                    </v-col>
                  </v-col> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5 bgSearch">
          <template v-if="mode === 'edit'">
            <v-btn @click="destroyGroup" color="error" text><v-icon size="20">mdi-delete</v-icon>Eliminar automatización</v-btn>
            <v-divider class="mx-5 my-1" vertical />
            <v-switch class="pa-0 ma-0" v-model="currentRule.is_active" @change="toggleGroupStatus" flat label="Desactivar automatización" color="success" hide-details></v-switch>
            <v-spacer />
            <v-btn @click="updateRule" :loading="loading" color="primary">Guardar cambios</v-btn>
          </template>
          <template v-else>
            <v-btn @click="clear(), dialog=false" color="primary" text>Cancelar</v-btn>
            <v-spacer />
            <v-btn @click="handlerSaveCreateOther" outlined :loading="loading">Guardar y agregar otra</v-btn>
            <v-btn @click="createRule(), dialog=false" :loading="loading" color="primary">Guardar</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create/edit -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    VEmptyState,
    MiniHeader
  },
  data: () => ({
    loading: false,
    loadingRules: false,
    groupsRules: [],
    membersList: [],
    mode: 'create',
    currentRule: {
      operator: 'Y',
      recipient: [],
      type: 'accept',
      send_mail: false,
      is_active: false
    },
    name: '',
    rules: [
      {
        comparisonOperatorsModel: null,
        attributeModel: null,
        value: null
      }
    ],
    activeWebhook: false,
    activeSftp: false,
    dialog: false,
    breadcrumbs: {
     main: 'Recepción',
     children: [
      {
        text: 'Automatización'
      }
     ]
   },
   attribute: ['Fecha de emisión', 'Referencia - Número', 'Monto total', 'Monto neto', 'Giro del receptor', 'Fecha de recepción SII'], // 'Fecha de recepción', 'Referencia - Tipo de documento',
   comparisonOperators: {
    'Fecha de emisión': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    // 'Fecha de recepción': {
    //   list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
    //   input: 'number'
    // },
    // 'Referencia - Tipo de documento': {
    //   list: ['Es igual a'],
    //   input: 'autocomplete',
    //   inputList: ['OC', 'HES', 'Contrato', 'DUS', 'AWB', 'MIC/DTA', 'Ficha ChileCompra', 'Proceso ChileCompra', 'Resolución', 'Nota de pedido']
    // },
    'Referencia - Número': {
      list: ['Es igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto total': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto neto': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Giro del receptor': {
      list: ['Uno de'],
      input: 'autocomplete',
      inputList: ['VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN ALMACENES ESPECIALIZADOS', 'VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN ALMACENES ESPECIALIZADOS', 'OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS']
    },
    'Fecha de recepción SII': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    }
   },
   documentModel: 'accept',
   documentOptions: [
    {
      id: 'accept',
      text: 'Se acepta',
      img: 'create',
      color: 'lightBlue'
    },
    {
      id: 'reject',
      text: 'Se rechaza',
      img: 'refused',
      color: 'lightRed'
    },
    {
      id: 'nothing',
      text: 'Se mantiene igual',
      img: 'equall',
      color: 'yellowLight'
    }
  ]
  }),
  computed: {
    ...mapState({
      members: state => state.accounts.membersList
    }),
    currentDocument () {
      return this.documentOptions.find(({id}) => id === this.documentModel)
    }
  },
  watch: {
    dialog () {
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
      .then(() => {
        this.membersList = this.members.map((item) => {
          return {
            ...item,
            customDisabled: false
          }
        })
      })
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /* eslint-disable */
    handlerEdit (group = {}) {
      this.mode = 'edit'
      this.currentRule = {
        id: group.id,
        operator: group.operator,
        type: group.action.toLowerCase(),
        send_mail: group.send_mail,
        recipient: group?.recipient ?? []
      }
      this.name = group?.name ?? ''
      this.rules = group.rules.map(({name, operator, values, list_values}) => {
        return {
          comparisonOperatorsModel: operator,
          attributeModel: name,
          value: values ?? list_values
        }
      })
      this.dialog = true
    },
    verifyEmails () {
      this.currentRule.recipient.forEach((item, index) => {
        this.membersList.forEach((m) => {
          if (index >= 2) {
            if (item.email !== m.email) m.customDisabled = true
          } else {
            m.customDisabled = false
          }
        })
      })
    },
    async handlerSaveCreateOther () {
      await this.createRule()
      this.clear()
    },
    addRow () {
      this.rules.push({
        comparisonOperatorsModel: null,
        attributeModel: null,
        value: null
      })
    },
    memberRemove (item) {
      const index = this.currentRule.recipient.indexOf(item)
      if (index >= 0) this.currentRule.recipient.splice(index, 1)
    },
    getList () {
      this.loadingRules = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'groups_rules_reception'
      })
      .then((resp) => {
        this.groupsRules = resp.data.results
      })
      .finally(() => {
        this.loadingRules = false
      })
    },
    updateRule () {
      this.$v.rules.$touch()
      this.$v.name.$touch()
      if (this.$v.rules.$invalid || this.$v.name.$invalid) return false

      let currentRules = []
      currentRules = this.rules.map(({attributeModel, comparisonOperatorsModel, value}) => {
        if (attributeModel === 'Giro del receptor') {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            list_values: value
          }
        } else {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            values: value
          }
        }
      })
      this.loading = true
      this.$store.dispatch('purchases/UPDATE', {
        resource: 'groups_rules_reception',
        id: this.currentRule.id,
        payload: {
          name: this.name,
          action: this.currentRule.type.toUpperCase(),
          send_mail: this.currentRule.send_mail,
          operator: this.currentRule.operator,
          recipient: this.mode === 'create' ? this.currentRule.recipient?.map(({email}) => email) ?? [] : this.currentRule.recipient,
          rules: currentRules
        }
      })
      .then(() => {
        this.$dialog.message.info('La automatización ha sido actualizada con éxito.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
        this.clear()
      })
    },
    createRule () {
      this.$v.rules.$touch()
      this.$v.name.$touch()
      if (this.$v.rules.$invalid || this.$v.name.$invalid) return false

      let currentRules = []
      currentRules = this.rules.map(({attributeModel, comparisonOperatorsModel, value}) => {
        if (attributeModel === 'Giro del receptor') {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            list_values: value
          }
        } else {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            values: value
          }
        }
      })
      this.loading = true

      this.$store.dispatch('purchases/CREATE', {
        resource: 'groups_rules_reception',
        payload: {
          name: this.name,
          action: this.currentRule.type.toUpperCase(),
          send_mail: this.currentRule.send_mail,
          operator: this.currentRule.operator,
          recipient: this.mode === 'create' ? this.currentRule.recipient?.map(({email}) => email) ?? [] : this.currentRule.recipient,
          rules: currentRules
        }
      })
      .then(() => {
        this.$dialog.message.info('La automatización ha sido creada con éxito.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loading = false
        this.clear()
      })
    },
    destroyGroup () {
      this.$store.dispatch('purchases/DESTROY', {
        resource: 'groups_rules_reception',
        id: this.currentRule.id
      })
      .then(() => {
        this.$dialog.message.info('La automatización se ha sido eliminado con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialog = false
        this.clear()
      })
    },
    toggleGroupStatus () {
      this.$store.dispatch('purchases/UPDATE', {
        resource: 'groups_rules_reception',
        id: this.currentRule.id,
        detail: 'change_status'
      })
      .then(() => {
        this.$dialog.message.info(`La automatización se ha ${!this.currentRule.is_active ? 'activado' : 'desactivado'} con éxito`)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      // .finally(() => {
      //   // this.dialog = false
      //   this.clear()
      // })
    },
    clear () {
      this.currentRule = {
        operator: 'Y',
        type: 'accept',
        recipient: [],
        send_mail: false,
        is_active: false
      }
      this.name = ''
      this.rules = [
        {
          comparisonOperatorsModel: null,
          attributeModel: null,
          value: null
        }
      ]
      this.$v.rules.$reset()
      this.$v.name.$reset()
      this.membersList = this.members.map((item) => {
        return {
          ...item,
          customDisabled: false
        }
      })
      this.mode = 'create'
    }
  },
  validations: {
    name: { required },
    rules: {
      $each: {
        comparisonOperatorsModel: { required },
        attributeModel: { required },
        value: { required }
      }
    }
  }
}
</script>
<style>
  .input-file-automation.v-text-field, .vti__input {
    background: transparent
  }

  .input-file-automation.v-file-input .v-file-input__text {
    width: auto;
    margin-top: 10px;
  }

  .input-file-automation .v-icon__svg {
    height: 20px !important;
    width: 20px;
  }
</style>